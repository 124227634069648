import { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { CiSquareQuestion } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { createRoutesFromElements, useLocation, useNavigate } from "react-router-dom";
import { counsellingType } from "../../../../../../models/request/counselling-request";
import { useCounsellingTypeListQuery, useCreateCounSellingMutation } from "../../../../../../services/counselling-service";
import { GetFormByType } from "../../../../../utils/Helpers";
import { FormQuestion, FormResponse } from "../../../../../../models/response/form-response";
import { Answer, FormAnswerRequest } from "../../../../../../models/response/FormAnswerDTO";
import { useGetFormQuery } from "../../../../../../services/form-service";
import { useCreateFormAnswerMutation } from "../../../../../../services/form-answers-service";
import toast from "react-hot-toast";


const RequestCounsellingModal = () => {
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false)
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const userId = parseInt(storedUserId);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [preferredStartAt, setPreferredStartAt] = useState<Date | null>(null);
  const [preferredEndAt, setPreferredEndAt] = useState<Date | null>(null);
  const [intakeAnswers, setIntakeAnswers] = useState<Answer[]>([]);
  const [intakeQuestion, setIntakeQuestion] = useState<FormQuestion[]>([])
  const { data: formResponseData, isLoading } = useGetFormQuery(16, {
    skip: !show, 
  });
  const [disabledFields, setDisabledFields] = useState<Array<boolean>>([]);
  const [createCounsel] = useCreateCounSellingMutation()
  const [counselData, setCounselData] = useState<counsellingType>({
    requestType: "",
    formId: null,
    requestedBy: [
      {
        userId: userId,
        phone: phoneNumber,
        firstName: firstName,
        lastName: lastName,
      },
    ],
    preferredStartAt: "",
    preferredEndAt: "",
  });
  const [requestId, setRequestId]=useState("")
   
 


  const handleFormChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCounselData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, -5); // Remove .000Z
      setPreferredStartAt(date);
      setCounselData((prevFormData) => ({
        ...prevFormData,
        preferredStartAt: formattedDate,
      }));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, -5); // Remove .000Z
      setPreferredEndAt(date);
      setCounselData((prevFormData) => ({
        ...prevFormData,
        preferredEndAt: formattedDate,
      }));
    }
  };

  const getParentAnswer = (formQuestion: FormQuestion) => {
    return intakeAnswers.find(
      (currentAnswer) =>
        currentAnswer.formQuestionId === formQuestion.parentQuestionId
    );
  };

  useEffect(() => {
    if (formResponseData) {
      setIntakeQuestion(formResponseData.data.questions)
    }
  }, [
    formResponseData,
  ]);

  const onQuestionAnswered = (
    answer: any,
    question: FormQuestion,
    isDropDown: Boolean | false
  ) => {
    if (isDropDown) {
      answer = JSON.parse(answer).value;
    }

    const updatedAnswers = intakeAnswers.map((ans) =>
      ans.formQuestionId === question.id
        ? { ...ans, answerProvided: answer }
        : ans
    );
    const answerExists = updatedAnswers.some(
      (ans) => ans.formQuestionId === question.id
    );
    setIntakeAnswers(
      answerExists
        ? updatedAnswers
        : [
            ...updatedAnswers,
            { answerProvided: answer, formQuestionId: question.id },
          ]
    );
  };

  const handleSubmitCounselRequest = async(e)=>{
    e.preventDefault();
    try{
      const response = await createCounsel(counselData);
      console.log("response", response)
      const { code, message, data } = response["data"];
      if (code === 0) {
        setIsSaved(true)
        setRequestId(data?.id)
        console.log("the session Id is:", data?.id)
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }

  }
  
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [pageSize] = useState(10000000);

  const { data: response } = useCounsellingTypeListQuery({
    page_index: currentPageIndex,
    page_size: pageSize,
  });

  const typeList = response?.data;

  return (
    <>
      <Button
        variant="dark"
        className="rounded-5 "
        style={{ fontSize: "12px" }}
        onClick={handleShow}
      >
        Request counselling <IoIosAdd />
      </Button>

      <Modal className="border-0" show={show} onHide={handleClose} size="lg">
        <Modal.Header className="border-0  mx-4 my-2" closeButton>
          <Modal.Title className="fs-6">Request Counselling</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mx-4 my-0 py-0">
          <Form >
          <Row className="gx-1 gy-2 mb-2">
            <Form.Group as={Col} md={6}>
              <Form.Label style={{ fontSize: "14px" }}>
                Counselling Type
              </Form.Label>
              <Form.Select
                size="lg"
                className="p-3 mb-3"
                aria-label="Default select example"
                name="requestType"
                onChange={handleFormChanged}
              >
                <option className="text-muted" value="" selected disabled>
                  Select Counselling
                </option>
                {typeList?.map((type) => (
                  <option key={type.value} value={type.name}>
                    {type.name}
                  </option>
                ))}
                </Form.Select> 
              </Form.Group>
            </Row>

            <Row className="gx-1 gy-3">
            <Form.Label style={{ fontSize: "14px" }}>
             Form Questions
            </Form.Label>
              {intakeQuestion.map((formQuestion, questionKey) => {

                let dateValue

                if (formQuestion.title ==="Date"){
                  
                  dateValue = formQuestion.title === "Date" ? new Date().toISOString().split('T')[0] : null;

                  return(
                    <></>
                  )

                }
                const parentAnswer = getParentAnswer(formQuestion);
                return (
                  <GetFormByType
                    key={questionKey}
                    question={formQuestion}
                    onSelectQuestion={onQuestionAnswered}
                    bindValue={dateValue || parentAnswer?.answerProvided}
                    disabled={disabledFields[questionKey]}
                  />
                );
              })}
            </Row>
          </Form>
   
          <Row className="gx-1 gy-3 mt-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}  >
            <DemoContainer components={["DateTimePicker"]}>
              <Row>
              <Form.Group as={Col} md={6}>
              <DateTimePicker
                  label="Start date & time"
                  value={preferredStartAt}
                  onChange={handleStartDateChange}
                />
              </Form.Group>
                <Col md={6}>
                  <DateTimePicker 
                    label="End date & time"
                    value={preferredEndAt}
                    onChange={handleEndDateChange}
                  />
                </Col>
              </Row>
            </DemoContainer>
          </LocalizationProvider>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="light"
            className="rounded-5"
            style={{ fontSize: "14px" }}
            onClick={(e)=>handleSubmitCounselRequest(e)
            }
          >
           Save details
          </Button>
          <Button
            variant="dark"
            className="rounded-5"
            style={{ fontSize: "14px" }}
            disabled= {!isSaved === true}
            onClick={() =>
              navigate("/e-counselling/approval", { state: { intakeAnswers, requestId } })
            }
          >
            Request <CiSquareQuestion />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestCounsellingModal;
