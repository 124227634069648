import React from "react";
import ReactDOM from "react-dom/client";
import "./presentation/assets/styles/custom.scss";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { store } from "./core/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./presentation/pages/auth/utils/AuthContext";
import Startup from "./presentation/Startup";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <Startup />
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
