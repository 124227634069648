import React, { useState } from "react";
import { Button, Card, Modal, Row, Image } from "react-bootstrap";
import { PiSuitcaseSimple } from "react-icons/pi";
import {  TbUsers } from "react-icons/tb";
import { VscBook } from "react-icons/vsc";
import { useGetAnsweredFormQuery } from "../../../../../../services/form-answers-service";
import PageLoader from "../../../../../components/PageLoader";

const ViewUnAssignedRequest = ({ assignedData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  };

  const { data, error, isLoading } = useGetAnsweredFormQuery(assignedData.id, {
    skip: !show, 
  });


  let icon = null;
  switch (assignedData?.requestType) {
    case "RELATIONSHIP":
      icon = <TbUsers />;
      break;
    case "EDUCATION":
      icon = <VscBook />;
      break;
    case "CAREER":
      icon = <PiSuitcaseSimple />;
      break;
    default:
      icon = null;
      break;
  }
  return (
    <>
      <Button
        variant="light"
        className="rounded-5 "
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        View Request
      </Button>
      <Modal key= {assignedData.id} show={show} onHide={handleClose}>
      {isLoading ? (
              <PageLoader />
            ):
        <Card>
          <Card.Body className="p-5">
            <div className="text-center">
              <h4 className="mb-0 fw-semibold">
                {" "}
                <span className="fs-2">{icon}</span> {assignedData?.requestType}
              </h4>
              <p className="mb-0">Ref Code: {assignedData?.referenceCode}</p>
            </div>
            <Row style={{ fontSize: "12px" }} className="">
              <div className="d-flex justify-content-between border-bottom py-2 mt-4">
                <span>Requested by</span>
                <span className="text-dark">
                  <Image
                    src={`https://ui-avatars.com/api/?name=${assignedData?.requestedBy[0]?.firstName}+${assignedData?.requestedBy[0].lastName}&background=random&size=32`}
                    alt="Avatar"
                    roundedCircle
                    className="me-2"
                  />
                  {assignedData?.requestedBy[0]?.firstName}{" "}
                  {assignedData?.requestedBy[0]?.lastName}
                </span>
              </div>
              <div className="d-flex  justify-content-between border-bottom py-2">
                <span>Preferred Start Date</span>
                <span className="">
                  {assignedData?.preferredStartAt
                    ? new Date(
                        assignedData?.preferredStartAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {assignedData?.preferredStartAt
                    ? new Date(
                        assignedData?.preferredStartAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span>Preferred End Date</span>

                <span className="">
                  {assignedData?.preferredEndAt
                    ? new Date(
                        assignedData?.preferredEndAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {assignedData?.preferredEndAt
                    ? new Date(
                        assignedData?.preferredEndAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span> Date Created</span>

                <span className="">
                  {assignedData?.createdOn
                    ? new Date(assignedData?.createdOn).toLocaleDateString()
                    : ""}{" "}
                </span>
              </div>
              {Array.isArray(data?.data) && data?.data.length > 0  && data?.data?.[0]?.answers?.map((item, index)=>{
                console.log("answer items:", item)
                return (
              <div key={index} className="d-flex justify-content-between border-bottom py-2">
                <span>{item.questionTitle}</span>
                <span className="text-dark">
                  {item.answerProvided}
                </span>
              </div>)
              })}
            </Row>
          </Card.Body>
        </Card>
          }
      </Modal>
    </>
  );
};

export default ViewUnAssignedRequest;
